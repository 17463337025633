
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { RouteRecordRaw } from 'vue-router'
import MainHeader from '@/components/MainHeader.vue'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'

dayjs.locale('cn')

@Options({
  components: {
    MainHeader
  }
})
export default class App extends Vue {
  // @screenStore.Getter('isAuto') isAuto!: boolean;
  public isShowNav = true;
  public zhCN = zhCN

  // @Watch('isAuto')
  // onAutoChange() {
  //   this.isShowNav = !this.isAuto;
  // }

  // @log
  @Watch('$route')
  onRouteChange (route: RouteRecordRaw) {
    const isAuto = JSON.parse(sessionStorage.getItem('isAuto') || 'false')
    // 自动登录时候不显示顶部导航
    if (isAuto) {
      this.isShowNav = false
      return
    }
    const { path } = route
    // 登录页和数据中心不显示顶部导航
    const hideRouteList = ['login', 'datacenter']
    const ret = hideRouteList.filter((item) => {
      return path.includes(item)
    })
    this.isShowNav = !ret.length
  }
}
