import { createStore } from 'vuex'
import CounterModule from '@/store/CounterModule'
import UserModule, { USER_NAME } from '@/store/user'
import NetGridModule from '@/store/netgrid'
import MenuModule, { MENU_NAME } from '@/store/menu'
import MapModule, { MAP_NAME } from '@/store/map'
import { getModule } from 'vuex-module-decorators'
import { namespace } from 'vuex-class'
import PanoModule, { PANO_NAME } from '@/store/pano'
import SelectModule, { SELECT_NAME } from '@/store/select'
import ReportModule, { REPORT_NAME } from './report'
import ScreenModule, { SCREEN_NAME } from '@/store/screen'
import SatelliteModule, { SATELLITE_NAME } from './satellite'
import AnalyseModule, { ANALYSE_NAME } from './analyse'
import CsMapModule, { CSMAP_NAME } from './csMap'

// 动态模块
// export interface StoreType{
//   counter: CounterModule;
//   user:UserModule,
//   netgrid:NetGridModule,
//   menu:MenuModule,
//   map:MapModule,
//   report:ReportModule,
//   pano:PanoModule,
//   select:SelectModule,
//   screen:ScreenModule,
//   analyse:AnalyseModule,
// }
// const store = createStore<StoreType>({})

// export default store
const store = createStore({
  // state: {
  //   // 全局的state
  //   // count: -1
  // },

  modules: {
    counter: CounterModule,
    user: UserModule,
    netgrid: NetGridModule,
    menu: MenuModule,
    report: ReportModule,
    map: MapModule,
    pano: PanoModule,
    select: SelectModule,
    screen: ScreenModule,
    satellite: SatelliteModule,
    analyse: AnalyseModule,
    csMap: CsMapModule

  }
})

export const mapModule = getModule(MapModule, store)

export const mapStore = namespace(MAP_NAME)

export const panoModule = getModule(PanoModule, store)

export const panoStore = namespace(PANO_NAME)

export const selectModule = getModule(SelectModule, store)

export const selectStore = namespace(SELECT_NAME)

export const reportModule = getModule(ReportModule, store)

export const reportStore = namespace(REPORT_NAME)

export const screenModule = getModule(ScreenModule, store)

export const screenStore = namespace(SCREEN_NAME)

export const satelliteModule = getModule(SatelliteModule, store)

export const satelliteStore = namespace(SATELLITE_NAME)

export const analyseModule = getModule(AnalyseModule, store)

export const analyseStore = namespace(ANALYSE_NAME)

export const csMapModule = getModule(CsMapModule, store)
export const csMapStore = namespace(CSMAP_NAME)

export const menuModule = getModule(MenuModule, store)

export const menuStore = namespace(MENU_NAME)
// export const userModule = getModule(UserModule, store)
// export const userStore = namespace(USER_NAME)
export default store
