import { Center, MultiPolyOptionItem } from '@/interfaces/common/geo'
import { ThreeScreenMode, WindowInfoItem } from '@/interfaces/screen.interface'
import { Module, VuexModule, Mutation, config, Action } from 'vuex-module-decorators'
import { MapName } from './map'
import { ExtentItem, SimplePanoInfo } from '@/interfaces/pano.interface'
import { OrthStageOptions } from '@/interfaces/ore.interface'
import * as api from '@/https/api'

// Set rawError to true by default on all @Action decorators
config.rawError = true

// 三屏实例相关的公共管理方法
export const SCREEN_NAME = 'screen'

export enum MidScreenType {
  PANO,
  THREED,
}

@Module({ namespaced: true, name: SCREEN_NAME })
export default class ScreenModule extends VuexModule {
  /**
   * @description 三屏所属的业务模块，
   * 用于切换业务模块的时候销毁其他业务三屏模块，相同业务模块三屏会共用且是show hide模式
   * @author neohua
   * @date 11/08/2023
   * @private
   * @type {ThreeScreenMode}
   * @memberof ScreenModule
   */
  private _screenMode!: ThreeScreenMode;
  private _isShowModal = false;
  // 三屏关闭后倒计时如果一定时间10秒内没有操作自动销毁三屏回收内存，切换模块也会立刻回收
  private _screenTimer = 0;
  private _modalTitle = '';
  // 是否自动登录，是的隐藏顶部状态栏
  private _isAuto = JSON.parse(sessionStorage.getItem('isAuto') || 'false');
  private _autoMenuList: string[] = JSON.parse(sessionStorage.getItem('_autoMenuList') || '[]');
  private _autoActiveMenu: string = sessionStorage.getItem('_autoActiveMenu') || '';
  /**
   * @description 业务图斑
   * @memberof ScreenModule
   */
  private _multiPolyList: MultiPolyOptionItem[] = [];
  // 信息弹窗内容
  private _windowInfo?: WindowInfoItem;

  /**
   * @description 正射相关的配置，用于判断是否有正射以及跨模块提供正射路径中心点等
   * @author neohua
   * @date 11/08/2023
   * @private
   * @memberof ScreenModule
   */
  private _orthId = 0;
  private _zoom = 16;
  private _minZoom = 10;
  private _maxZoom = 22;
  private _extent: ExtentItem | null = null;
  private _orthUrl = '';
  private _orthName = '';
  private _orthCenter!: Center;
  private _panoramaList: SimplePanoInfo[] = [];
  private _orthOptions: OrthStageOptions[] = [];

  get modalTitle (): string {
    return this._modalTitle
  }

  get isAuto (): boolean {
    return this._isAuto
  }

  get autoMenuList () {
    return this._autoMenuList
  }

  get autoActiveMenu (): string {
    return this._autoActiveMenu
  }

  get windowInfo (): WindowInfoItem | undefined {
    return this._windowInfo
  }

  get isShowModal (): boolean {
    return this._isShowModal
  }

  get screenTimer (): number {
    return this._screenTimer
  }

  get orthName (): string {
    return this._orthName
  }

  get orthId (): number {
    return this._orthId
  }

  get zoom (): number {
    return this._zoom
  }

  get minZoom (): number {
    return this._minZoom
  }

  get maxZoom (): number {
    return this._maxZoom
  }

  get extent () {
    return this._extent
  }

  get panoramaList (): SimplePanoInfo[] {
    return this._panoramaList
  }

  get orthOptions (): OrthStageOptions[] {
    return this._orthOptions
  }

  get mapList () {
    if (this._orthId) {
      return [MapName.ELE, MapName.SATE, MapName.OL]
    }
    return [MapName.ELE, MapName.SATE]
  }

  get orthUrl (): string {
    return this._orthUrl
  }

  get orthStage (): number {
    const item = this._orthOptions.find((item) => item.value === this._orthUrl)
    if (!item) {
      return 1
    }
    return item.stage
  }

  get orthCenter (): Center {
    return this._orthCenter
  }

  get screenMode () {
    return this._screenMode
  }

  get multiPolyList () {
    return this._multiPolyList
  }

  @Mutation
  setAutoActiveMenu (autoActiveMenu: string) {
    this._autoActiveMenu = autoActiveMenu
    sessionStorage.setItem('_autoActiveMenu', autoActiveMenu)
  }

  @Mutation
  setAutoMenuList (autoMenuList: string[]) {
    this._autoMenuList = autoMenuList
    sessionStorage.setItem('_autoMenuList', JSON.stringify(autoMenuList))
  }

  @Mutation
  setIsAuto (isAuto: boolean) {
    this._isAuto = isAuto
    sessionStorage.setItem('isAuto', JSON.stringify(isAuto))
  }

  @Mutation
  setZooms ({ zoom, min_zoom, max_zoom }: { zoom: number, min_zoom: number, max_zoom: number }) {
    this._zoom = zoom
    this._maxZoom = max_zoom
    this._minZoom = min_zoom
  }

  @Mutation
  setModalTitle (modalTitle: string) {
    this._modalTitle = modalTitle
  }

  @Mutation
  setWindowInfo (windowInfo: WindowInfoItem) {
    this._windowInfo = windowInfo
  }

  @Mutation
  setIsShowModal (isShowModal: boolean) {
    this._isShowModal = isShowModal
  }

  @Mutation
  setExtent (extent: ExtentItem | null) {
    this._extent = extent
  }

  @Mutation
  setOrthOptions (orthOptions: OrthStageOptions[]) {
    this._orthOptions = orthOptions
  }

  @Mutation
  setScreenTimer (screenTimer: number) {
    this._screenTimer = screenTimer
  }

  @Mutation
  setPanoramaList (panoramaList: SimplePanoInfo[]) {
    this._panoramaList = panoramaList
  }

  @Mutation
  setOrthName (orthName: string) {
    this._orthName = orthName
  }

  @Mutation
  setOrthId (orthId: number) {
    this._orthId = orthId
  }

  @Mutation
  setOrthUrl (orthUrl: string) {
    this._orthUrl = orthUrl
  }

  @Mutation
  setOrthCenter (orthCenter: Center) {
    this._orthCenter = orthCenter
  }

  @Mutation
  resetOrthVars () {
    this._orthId = 0
    this._orthUrl = ''
    this._orthCenter = [0, 0]
    this._panoramaList = []
    this._extent = null
    this._zoom = 16
    this._minZoom = 10
    this._maxZoom = 22
    this._windowInfo = undefined
  }

  @Mutation
  setScreenMode (screenMode: ThreeScreenMode) {
    this._screenMode = screenMode
    // if (this._screenMode === 'none') {

    // }else{
    if (this._screenTimer) {
      clearTimeout((this._screenTimer))
      this._screenTimer = 0
    }
    // }
  }

  @Mutation
  setMultiPolyList (multiPolyList: MultiPolyOptionItem[]) {
    this._multiPolyList = multiPolyList
  }

  @Action({})
  async fetchOrthStageList (name: string) {
    const { data } = await api.getSimpleOrthList({ name, select: 'name,stage,path' })
    if (data.length < 2) {
      return false
    }
    const options = data.map((item) => {
      return {
        label: `${item.name}${item.stage}期`,
        value: item.path,
        stage: item.stage
      }
    })
    this.setOrthOptions(options)
  }
}
