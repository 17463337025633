
import { Options, Vue } from 'vue-class-component'
import { isWjUm, getKeyTitle } from '@/helpers/platform.helper'
import { UserInfo } from '@/interfaces/user'
import { namespace } from 'vuex-class'
import { USER_NAME } from '@/store/user'
const userStore = namespace(USER_NAME)

@Options({
  components: {
  }
})
export default class MainHeader extends Vue {
  @userStore.Getter('userInfo') userInfo!: UserInfo;

  public isWjUm = isWjUm();
  public keyTitle = getKeyTitle();
}
