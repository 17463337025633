import { Geojson, Point, TTGeojson } from '@/interfaces/common/geo'
import { Geometry, Position } from '@turf/turf'
import gcoord, { CRSTypes, GeoJSON } from 'gcoord'
import wellknown from 'wellknown'

export function transformCoord (point: Point, fromCrs: CRSTypes, toCrs: CRSTypes) {
  return gcoord.transform(point, fromCrs, toCrs)
}

export function transformGeoJson (geojson: Geojson, fromCrs: CRSTypes, toCrs: CRSTypes) {
  gcoord.transform(geojson as GeoJSON, fromCrs, toCrs)
}
export function geojsonW842gd (geojson: Geojson) {
  transformGeoJson(geojson, gcoord.WGS84, gcoord.GCJ02)
}

export function geojsonGd2W84 (geojson: Geojson) {
  transformGeoJson(geojson, gcoord.GCJ02, gcoord.WGS84)
}

export function w842gd (point: Point) {
  return transformCoord(point, gcoord.WGS84, gcoord.GCJ02)
}
export function gd2w84 (point: Point) {
  return transformCoord(point, gcoord.GCJ02, gcoord.WGS84)
}

export function opacity2Hex (opacity = 0) {
  const opacityMap: Record<number, string> = {
    1: 'FF',
    0.9: 'E6',
    0.8: 'CC',
    0.7: 'B3',
    0.6: '99',
    0.5: '80',
    0.4: '66',
    0.3: '4D',
    0.2: '33',
    0.1: '1A',
    0: '00'
  }
  return opacityMap[opacity]
}

export function formatArea (val: number): number {
  return +(val.toFixed(1))
}
export function formatMuArea (val: number): number {
  return +(val * 0.0015).toFixed(2)
}

// 亩换算成平方米
export function acresToSquareMeters (acres: number) {
  // 1 acre 约等于 4046.86 平方米
  // const squareMeters = acres * 4046.86
  const squareMeters = acres / 0.0015
  return squareMeters
}

/**
 * @description 将17,399的转化为17399
 * @author neohua
 * @date 21/08/2023
 * @export
 * @param {string} input
 * @returns {*}  {number}
 */
export function removeCommasAndParseInt (input: string): number {
  input = input.trim()
  const cleanedString = input.replace(/,/g, '') // 去除逗号
  return parseInt(cleanedString, 10) // 解析为整数
}

/**
 * @description 将数字转为千位逗号隔开
 * @author neohua
 * @date 21/08/2023
 * @export
 * @param {number} value
 * @returns {*}  {string}
 */
export function formatNumberWithCommas (value: number): string {
  return value.toLocaleString()
}

/**
 * @description 往下取整
 * @author neohua
 * @date 21/08/2023
 * @export
 * @param {number} number
 * @returns {*}  {number}
 */
export function roundDownToNearestThousand (value: number, step: number): number {
  return Math.floor(value / step) * step
}

/**
 * @description 将wkt格式转为geojson格式的Geometry
 * @param wkt
 * @returns
 */
export function convertWKTToGeoJSON (wkt: string) {
  const geojson = wellknown.parse(wkt)
  return geojson
}

/**
 * @description 将geometry格式转geojson
 * @param geojson
 * @returns
 */
export function convertGeoJSON (geometry: Geometry) {
  // 提取所需的属性
  const { type, coordinates } = geometry

  // 创建新的 GeoJSON 对象
  const geojson = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: type,
          coordinates: coordinates
        },
        properties: {}
      }
    ]
  }

  return geojson
}

/**
 * @description 将普通哈希颜色值转换krpano用的颜色值
 * @param {*} hashColor 哈希颜色值
 * @returns string krpano颜色值16
 * @example toPanoColor("#FF0000")="0XFF0000"
 */
export function toPanoColor (hashColor: string) {
  return hashColor.replace('#', '0x')
}
/**
 * @description 判断字符串是不是以数字开头
 * @param {*} str
 * @returns boolean
 * @example isNumericStart('0aba')=true
 */
function isNumericStart (str: string) {
  return /^\d/.test(str)
}

/**
 * @description 将普通的字符串转换为可作为krpano hotspot的name
 * @param {*} str
 * @returns string
 * @example toPanoName('0aba')='hs_oaba'
 */
export function toPanoName (name: string, prefix = 'hs') {
  if (isNumericStart(name)) {
    name = `${prefix}_${name}`
  }
  return name.replaceAll('.', '_')
}

/**
 * @description 获得echart需要的颜色
 * @param color 颜色 例如 "#fff",或者渐变色 '#65EA9A,#54C6C2'
 * @param isReverse 是否反转渐变色
 * @returns
 */
export function toChartColor (color: string, isReverse = false) {
  if (color.includes(',')) {
    let [color1, color2] = color.split(',')
    if (isReverse) {
      [color2, color1] = [color1, color2]
    }
    return {
      type: 'linear',
      x: 0,
      y: 0,
      x2: 1,
      y2: 0,
      colorStops: [
        { offset: 0, color: color1 }, // 渐变起始颜色
        { offset: 1, color: color2 } // 渐变结束颜色
      ]
    }
  }
  return color
}

export const formatGd = <T extends TTGeojson<Geojson>>(item: T) => {
  geojsonW842gd(item.geojson)
  if (item.flight_range && item.flight_range !== item.geojson) {
    geojsonW842gd(item.flight_range)
  }
  return item
}

/**
 * @description 将数字字符串保留
 * @param numStr
 * @returns
 */
export function truncateDecimalString (numStr: string, decimalPlaces = 1): string {
  const num: number = parseFloat(numStr)
  if (isNaN(num)) {
    // return 'Invalid input'
    return ''
  }
  const truncatedNum: number = Math.trunc(num * 10) / 10
  return truncatedNum.toFixed(decimalPlaces)
}
